import $ from 'jquery';

$(function(){
  $(".insurance-select").each(function () {
    showInsurance(this)
});
});

$('.insurance-select').change(function() {
  showInsurance(this);
});

function showInsurance(el){
  let covered = $(el).find("option:selected").data('covered');
  if(covered){
    $(el).parent().find('.insurance-true').removeClass('d-none');
    $(el).parent().find('.insurance-false').addClass('d-none');
  }else {
    $(el).parent().find('.insurance-true').addClass('d-none');
    $(el).parent().find('.insurance-false').removeClass('d-none');
  }
}
