(function () {
    if (document.querySelector('.js-toggle-navigation')) {
        document.querySelector('.js-toggle-navigation').addEventListener('click', function (e) {
            document.body.classList.toggle('menu-open');
        });
    }

    if (document.querySelector('.js-nav-item')) {
        const navItems = document.querySelectorAll('.js-nav-item');

        navItems.forEach((item) => {
            var parentLi = item.closest('li');

            item.addEventListener('touchend', (e) => {
                if (!parentLi.classList.contains('menu-open')) {
                    e.preventDefault();
                    const openItem = document.querySelector('.header__nav-item.menu-open');

                    if (openItem) {
                        openItem.classList.remove('menu-open');
                    }
                    parentLi.classList.add('menu-open');
                }
            });
        });
    }

    if (document.querySelector('.js-mobile-nav-item')) {
        const mobileNavItems = document.querySelectorAll('.js-mobile-nav-item');

        mobileNavItems.forEach((item) => {
            var parentLi = item.closest('li');

            item.addEventListener('click', (e) => {
                if (!parentLi.classList.contains('menu-open')) {
                    e.preventDefault();
                    parentLi.classList.add('menu-open');
                } else {
                    parentLi.classList.remove('menu-open');
                }
            });
        });
    }

    if (document.querySelector('.js-toggle-map-filters')) {
        document.querySelector('.js-toggle-map-filters').addEventListener('click', function (e) {
            this.classList.toggle('filters-visible');
            document.querySelector('.js-map-filters').classList.toggle('is-visible');
        });
    }
})();
