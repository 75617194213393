import $ from 'jquery';
import ko from 'knockout';

/**
 * Google maps code
 */
var geocoder, map, userlocation = null;
var mapApp;

function getQueryParams(qs) {
  qs = qs.split('+').join(' ');

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while (tokens = re.exec(qs)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

async function initialize() {
  const { Loader } = await import("@googlemaps/js-api-loader");
  const loader = new Loader({
    apiKey: 'AIzaSyAXuaoOKNjHmgM_xUPQlwgdsLFwP8E685I',
    version: "weekly",
  });

  await loader.load();

  geocoder = new google.maps.Geocoder();
  var mapOptions = {
    center: { lat: 52.2129919, lng: 5.2793703 },
    zoom: 8
  };
  map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

}

function distance(lon1, lat1, lon2, lat2) {

  // check if input is valid
  if (!lon1 || !lat1 || !lon2 || !lat2) {
    return null;
  }

  var R = 6371; // Radius of the earth in km
  var dLat = (lat2 - lat1).toRad();  // Javascript functions in radians
  var dLon = (lon2 - lon1).toRad();
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

/** Converts numeric degrees to radians */
if (typeof (Number.prototype.toRad) === "undefined") {
  Number.prototype.toRad = function () {
    return this * Math.PI / 180;
  }
}

//google.maps.event.addDomListener(window, 'load', initialize);

$(document).ready(async function () {
  if (document.getElementById('map-canvas')) {
    await initialize();

    var MapViewModel = function () {
      var self = this;
      self.userlocation = ko.observable(null);
      self.errorText = ko.observable("");
      self.params = getQueryParams(location.search);

      var BBZLocation = function (lat, lng, title, city, img, url, type) {
        var bbzl = this;
        this.location = { lat: lat, lng: lng };
        this.title = title;
        this.city = city;
        this.img = img;
        this.url = url;
        this.type = type;
        this.distance = ko.computed(function () {
          if (self.userlocation() == null) {
            return 0;
          } else {
            var ul = self.userlocation().getPosition();
            var d = distance(ul.lng(), ul.lat(), bbzl.location.lng, bbzl.location.lat);
            return d.toFixed(1);
          }
        }, self);
        return this;
      }


      self.location = ko.observable(null);
      self.getLocation = function () {
        navigator.geolocation.getCurrentPosition(function (location) {
          self.location(location);
          self.storeLocation(location.coords.latitude, location.coords.longitude, (new Date()).getTime());
          self.setLocationMarker(new google.maps.LatLng(location.coords.latitude, location.coords.longitude));
          //window.location.href = "https://cms.brabantzorg.eu/Paginas/locatie.aspx";
        }, function (err) {
        }, { maximumAge: 100, timeout: 6000, enableHighAccuracy: true });
      }


      self.postCode = ko.observable("");
      self.onsearch = function (d, e) {
        if (e.keyCode === 13) {
          self.geoCode();
          return false;
        }
        return true;
      };

      self.geoCode = function () {
        var query = self.postCode();
        //query = query.trim().toUpperCase().replace(" ","");
        //var re = new RegExp("^[0-9]{4}[\s]?[A-Za-z]{2}$");
        //if (!re.test(query)) { self.errorText("Foutieve postcode."); return; };
        self.errorText("");
        geocoder.geocode({ 'address': query }, function (results, status) {

          if (status == google.maps.GeocoderStatus.OK) {
            map.setCenter(results[0].geometry.location);

            self.setLocationMarker(results[0].geometry.location);
            self.errorText("");
          } else {
            //status
            self.errorText("Wij hebben uw postcode of plaats niet kunnen vinden.");
          }
        });
        return false;
      }

      self.loadUserLocation = function () {
        if (self.params.postcode) {
          // Load Postal code
          self.postCode(self.params.postcode);
          self.geoCode();
        } else {
          // ask for HTML5 Geolocation permission
          var center = { lat: 52.2129919, lng: 5.2793703 }; // DEFAULT LOCATION
          if (self.location()) {
            center = self.location();
            //map.setCenter({ lat: center.lat, lng: center.lng });
            self.setLocationMarker(new google.maps.LatLng(center.lat, center.lng));
          } else {
            self.getLocation();
          }

        }
      }

      self.setLocationMarker = function (location) {
        if (self.userlocation() != null) {
          self.userlocation().setMap(null);
        }

        var userInfoWindow = new google.maps.InfoWindow({
          content: "<b style=\"color:#c6047d\">Uw locatie</b>"
        });

        var marker = new google.maps.Marker({
          map: map,
          zIndex: -1,
          title: "Uw locatie",
          position: location,
          icon: "/assets/images/user-marker.png"
        });
        self.userlocation(marker);

        google.maps.event.addListener(marker, 'click', function () {
          userInfoWindow.open(map, marker);
        });

        map.setCenter(location);
      }

      self.storeLocation = function (lat, lng, time) {
        localStorage["bbz_location"] = JSON.stringify({ lat: lat, lng: lng, timestamp: time });
      }

      self.location = function () {
        var loc = localStorage.getItem("bbz_location");
        if (loc != null && ((new Date()).getTime() - loc.timestamp) < (30 * 60 * 1000)) {
          return JSON.parse(loc);
        } else {
          return false;
        }
      }

      self.filter = ko.observable("");

      if (self.params.filter) {
        self.filter(self.params.filter);
      } else {
        self.filter("Locaties");
      }

      self.locations = ko.observableArray([]);
      self.loadLocations = function () {
        /**
         * /umbraco/surface/api/getmaplocations
         */
        $.get("/umbraco/surface/api/getmaplocations", function (data) {
          $.each(data, function () {
            var lat = null;
            var lng = null;
            if (this.Longitude && this.Latitude) {
              lat = parseFloat(this.Latitude);
              lng = parseFloat(this.Longitude);

              var filters = [];
              if (this.Filters) {
                filters = this.Filters;
              }

              var hyperlink = { Url: "", Description: this.Hyperlinktekst };
              if (this.HyperlinkUrl)
                hyperlink.Url = this.HyperlinkUrl;

              self.locations.push(new BBZLocation(lat, lng, this.Regel1, this.Regel2, this.Bannerimage, hyperlink, filters));
            }
          });
        });


        // set map bounds
        self.setMapBounds();
        /*})
        .fail(function(err){
          console.error(err);
        });*/
      };

      self.pushPins = ko.observableArray();
      self.infoWindow = ko.observable(null);

      self.setMapBounds = function (filtered) {
        if (!filtered)
          filtered = self.filteredLocations();

        var bounds = new google.maps.LatLngBounds();

        for (var i = 0; i < filtered.length; i++) { // Add limit of markers here
          bounds.extend(new google.maps.LatLng(filtered[i].location.lat, filtered[i].location.lng));
        }

        if (self.userlocation()) {
          bounds.extend(self.userlocation().getPosition());
        }

        if (filtered.length > 0) {
          map.fitBounds(bounds);
        } else {
          bounds.extend(new google.maps.LatLng(52.2129919, 5.2793703));
          map.setZoom(8);
        }

      };

      self.addPin = function (lat, lng, title, city, img, url) {


        var latlng = new google.maps.LatLng(lat, lng);
        var marker = new google.maps.Marker({
          position: latlng,
          map: map,
          title: title,
          icon: "../assets/images/bbz-marker.png"
        });

        google.maps.event.addListener(marker, 'click', function (a, b) {
          // info window

          var locs = self.locations();
          var mi = null;
          locs.forEach(function (loc, index) {
            if (loc.marker == marker)
              mi = loc;
          });

          // format URL
          var link = "";
          if (url) {
            link = '<a href="' + url.Url + '">' + url.Description + '</a>';
          }

          var distanceText = (mi.distance() > 0) ? mi.distance() + "km" : "";
          var contentString = '<table class="geo-table">' +
            '<tr>' +
            '<td>' +
            '<div class="geo-image" style="margin:10px; background-size:contain; background-repeat:no-repeat; width:100px; height:100px; background-image:url(\'' + img + '\')"></div>' +
            '</td>' +
            '<td>' +
            '<div class="geo-content">' +
            '<div class="geo-title">' + title + '</div>' +
            '<div class="geo-city">' + city + '</div>' +
            '<div class="geo-link">' +
            link +
            '</div>' +
            '</div>' +
            '</td>' +
            '<td>' +
            '<div class="geo-distance">' + distanceText + '</div>' +
            '</td>' +
            '</tr>' +
            '</table>';

          if (self.infoWindow() != null) {
            self.infoWindow().setMap(null);
            self.infoWindow(null);
          }
          self.infoWindow(new google.maps.InfoWindow({
            content: contentString
          }));

          self.infoWindow().open(map, marker);
        });

        self.pushPins.push(marker);
        return marker;
      }

      self.resetPins = function () {
        ko.utils.arrayForEach(self.pushPins(), function (pin) {
          pin.setMap(null);
        });
        self.pushPins([]);
      }

      self.filteredLocations = ko.computed(function () {
        self.resetPins();
        var filter = self.filter().toLowerCase();
        var filtered = [];
        if (!filter) {
          var t = self.locations();
          t.forEach(function (item) {
            if (item.location.lat != null && item.location.lng != null) {
              item.marker = self.addPin(item.location.lat, item.location.lng, item.title, item.city, item.img, item.url);
              filtered.push(item);
            }
          });
        } else {
          filtered = ko.utils.arrayFilter(self.locations(), function (location) {
            var match = false;
            location.type.forEach(function (l, i) {
              if (l.toLowerCase() == filter)
                match = true;
            });
            return match;
          });

          filtered.forEach(function (item) {
            if (item.location.lat != null && item.location.lng != null)
              item.marker = self.addPin(item.location.lat, item.location.lng, item.title, item.city, item.img, item.url);
          });

        }

        if (filtered.length == 0 && self.userlocation()) {
          map.setCenter(self.userlocation().getPosition());
          map.setZoom(8);
          return;
        }

        // sort by distance
        filtered.sort(function (a, b) {
          var c = parseFloat(a.distance());
          var d = parseFloat(b.distance());
          return c - d;
        });

        // set bounds on the map
        self.setMapBounds(filtered);

        return filtered;

      }, this);

      // Stuff to initialize in the View Model
      self.loadUserLocation();

    }
    mapApp = new MapViewModel();
    ko.applyBindings(mapApp, document.getElementById('map-control'));
    mapApp.loadLocations();
  }
});
