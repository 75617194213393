import $ from 'jquery';

(function () {
    /*
     * show popup if it is on the page
     */
    if ($('#calamitiesPopup').length) {
        $('#calamitiesPopup').modal();
    }
})();
